import { DEFAULT_TITLE } from 'utils/constants';

/**
 * Creates page titles for SEO, title cases letters, lowercases "and", appends
 * "Residency" / "Fellowship", concats multiple specialties with "&", and adds
 * program name with "Program" when program selected.
 *
 * Examples:
 * Brain Injury Medicine (Physical Medicine and Rehabilitation) Fellowship Programs
 *
 */

const titleCase = str =>
  str.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).replace('And', 'and');

const pageTitle = (specialties, program) => {
  if (!specialties?.length) return DEFAULT_TITLE;
  let title = specialties
    .map(o =>
      titleCase(`${o.label} ${o.residency ? 'Residency' : 'Fellowship'}`)
    )
    .join(' & ');
  title += ' Programs';
  if (program) title += ` - ${program.name}`;
  return title;
};

export default pageTitle;
