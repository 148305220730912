import { useEffect } from 'react';
import { useRouter } from 'next/router';
import dynamic from 'next/dynamic';
import { NextSeo } from 'next-seo';

import useRouteLoading from 'hooks/useRouteLoading';
import groupBy from 'utils/groupby';
import pipe from 'utils/pipe';
import pageDescription from 'utils/pageDescription';
import pageTitle from 'utils/pageTitle';
import sortKeys from 'utils/sortkeys';
import * as query from 'utils/query';
import { useProgram, useSpecialty } from 'utils/store';
import { allSpecialties, programByPid, programsBySpecialty } from 'utils/api';

// TODO: Trace error when trying to dynamically import.
import Loading from 'components/Loading';

const Map = dynamic(() => import('components/Map'));
const ProgramDetails = dynamic(() => import('components/ProgramDetails'));
const SelectProgram = dynamic(() => import('components/SelectProgram'));
const SelectSpecialty = dynamic(() => import('components/SelectSpecialty'));
const Sidebar = dynamic(() => import('components/Sidebar'));

const groupByState = groupBy('state');
const sortByState = pipe(groupByState, sortKeys);

const Specialty = ({ program, programs, sids, specialties }) => {
  const router = useRouter();

  // TODO: Use context to pass down loading.
  const loading = useRouteLoading() || router.isFallback;

  // TODO: Check if setters are being used since move to static pages.
  const programState = useProgram(state => state.program);
  const specialtyState = useSpecialty(state => state.specialty);

  const programList = programs && Object.entries(programs);
  // Using `[].concat` intead of `.flat()` to fix issue: WEB-5
  const mapList = programs && [].concat(...Object.values(programs));

  const title = pageTitle(specialtyState, programState);
  const description = pageDescription(title);

  // Handle program change here since map marker selections and dropdown
  // selections trigger a route change.
  useEffect(() => {
    if (programState && programState.pid !== program?.pid) {
      const path = `${sids?.join('/_/')}/program/${programState.pid}`;
      router.push('/specialty/[[...param]]', `/specialty/${path}`);
    }
  }, [program?.pid, programState?.pid, sids]);

  return (
    <>
      {!loading && <NextSeo title={title} description={description} />}
      <div className="w-screen h-screen flex flex-col">
        {loading && <Loading.Full />}
        <div className={`flex-1 overflow-hidden`}>
          <div className="h-full w-full flex">
            <Sidebar
              content={program && !loading && <ProgramDetails data={program} />}
              header={
                <>
                  <SelectSpecialty
                    list={specialties}
                    searchterm={sids}
                    loading={loading}
                    selected={sids}
                  />
                  {specialtyState?.length ? (
                    <SelectProgram
                      entries={programList}
                      loading={loading}
                      selected={program}
                    />
                  ) : null}
                </>
              }
            />
            <div className="flex-1 fixed bottom-16 h-full w-full md:relative md:bottom-0 md:mb-0">
              {<Map loading={loading} values={mapList} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export async function getStaticPaths() {
  const specialties = await allSpecialties();
  const paths = specialties.map(specialty => ({
    params: { param: [specialty.value] }
  }));

  return { paths, fallback: true };
}

export async function getStaticProps({ params }) {
  const sids = query.specialtyIds(params.param) || null;
  const pid = query.programId(params.param) || null;

  const specialties = await allSpecialties();
  const specialtyList = specialties?.filter(o => sids?.includes(o.value));
  const specialtyLabels = specialtyList?.map(o => o.key);

  const programList = await programsBySpecialty(specialtyLabels);
  const programs = sortByState(programList) || {};

  // TODO: Figure out a better way to add index (not passing in labels).
  const program = pid?.length ? await programByPid(pid, specialtyLabels) : null;

  return { props: { program, programs, sids, specialties } };
}

export default Specialty;
