import create from 'zustand';

import { TAB } from 'utils/constants';

// Persist to localstorage
const persist = config => (set, get, api) => {
  const initialState = config(
    args => {
      set(args);
      window.localStorage.setItem('state', JSON.stringify(get()));
    },
    get,
    api
  );

  const restoredState =
    typeof window === 'undefined'
      ? {}
      : JSON.parse(localStorage.getItem('state'));

  return {
    ...initialState,
    ...restoredState
  };
};

// Log every time state is changed
const log = config => (set, get, api) =>
  config(
    args => {
      console.log('medmap.io :: applying', args);
      set(args);
      console.log('medmap.io :: new state', get());
    },
    get,
    api
  );

const [useStore] = create(set => ({}));

export const [useDrawerState] = create(set => ({
  show: false,
  setShow: newShow => set(() => ({ show: newShow }))
}));

const DEFAULT_CENTER = [37.09024, -95.712891];
const DEFAULT_ZOOM = 5;

export const [useMapState] = create(set => ({
  bounds: { ne: [], sw: [] },
  center: DEFAULT_CENTER,
  zoom: DEFAULT_ZOOM,
  reset: () => set(() => ({ center: DEFAULT_CENTER, zoom: DEFAULT_ZOOM })),
  resetCenter: () => set(() => ({ center: DEFAULT_CENTER })),
  setBounds: ({ bounds, center, zoom }) =>
    set(() => ({ bounds, center, zoom })),
  setCenter: (newCenter = []) => set(() => ({ center: [...newCenter] })),
  setZoom: newZoom => set(() => ({ zoom: newZoom })),
  zoomIn: (n = 1) => set(state => ({ zoom: state.zoom + n })),
  zoomOut: (n = 1) => set(state => ({ zoom: state.zoom - n })),
  zoomTo: ({ center, zoom }) => set(() => ({ center, zoom }))
}));

export const [useModalState] = create(set => ({
  show: false,
  setShow: newShow => set(() => ({ show: newShow }))
}));

export const [useProgram, apiProgram] = create(set => ({
  program: undefined,
  resetProgram: () => set(() => ({ program: undefined })),
  setProgram: newProgram => set(() => ({ program: newProgram }))
}));

export const [useSpecialty, apiSpecialty] = create(set => ({
  specialty: undefined,
  setSpecialty: newSpecialty =>
    set(() => {
      // Reset selected program when selected specialty changes.
      apiProgram.getState().setProgram();

      return { specialty: newSpecialty };
    })
}));

export const [useSearchState] = create(
  persist(set => ({
    fellowship: true,
    residency: true,
    setFellowship: newState => set(() => ({ fellowship: newState })),
    setResidency: newState => set(() => ({ residency: newState }))
  }))
);

export const [useTabStore] = create(
  log((set, get) => ({
    tab: TAB.MAP,
    setTab: newTab => set(() => ({ tab: newTab })),
    // Helper getter functions
    isDetailsTab: () => get().tab === TAB.DETAILS,
    isInfoTab: () => get().tab === TAB.INFO,
    isMapTab: () => get().tab === TAB.MAP,
    // Helper setter functions
    setDetailsTab: () => set(() => ({ tab: TAB.DETAILS })),
    setInfoTab: () => set(() => ({ tab: TAB.INFO })),
    setMapTab: () => set(() => ({ tab: TAB.MAP }))
  }))
);

export default useStore;
